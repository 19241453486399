<template style="padding-bottom: 20px">

  <div class="reporting">

    <h1>{{$t("Reporting")}}</h1>

    <h3>{{$t("User List")}}</h3>

      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                class="user-search"
                v-model="search_team"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">

          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers_team"
          :items="team"
          :search="search_team"
          :items-per-page="25"
          class="elevation-1 transparent white--text"
          :hide-default-footer="team.length < 5"
      >
        <template v-slot:item.lastActivity="{item}" class="ml-0">
          {{formatLocalDate(item.lastActivity, true)}}
        </template>
      </v-data-table>

    <h3>{{$t("Your courses")}}</h3>
    <v-card class="transparent">
      <v-card-title class="white-text" style="display: block;">
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('')"
              single-line
              hide-details
              class="white-text"
              id="searchfield"

            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <div></div>
        <v-row>
          <v-col style="max-width: 600px;">
            <v-autocomplete
                v-model="selectedTags"
                :items="allTags"
                filled
                chips
                label="Filter"
                item-text="name"
                item-value="id"
                item-color="dark-grey"
                multiple
                class="tag-filter"
                @change="(event) => updateList(event)"
            >
              <!--                      append-icon="mdi-plus"-->
              <!--                      @click:append="addLabel"-->

              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    :color="data.item.color"
                    text-color="white"
                    @click="data.select"
                    @click:close="removeTag(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content class="ma-0">
                    <v-list-item-title>
                      <v-chip
                          v-html="data.item.name"
                          class="ma-0"
                          close
                          :color="data.item.color"
                          text-color="white"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :items="courses"
        :search="search"
        :items-per-page="15"
        class="elevation-1 transparent white-text"
        :hide-default-footer="courses.length < 5"
        @click:row="itemSelected"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <!--
        <template v-slot:item.author="{ item }">
          <v-avatar
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          {{item.author.firstName}} {{item.author.lastName}}
        </template>
        -->
        <template v-slot:item.status="{ item }" class="ml-0">
          <v-chip
              :color="item.status == 'published' ?  '#005AA9' : 'success'"
              class="ma-0"
              small
              style="width: 110px;justify-content: center;"

          >
            {{item.status == 'published' ? 'läuft' : 'abgeschlossen'}}
          </v-chip>
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{formatLocalDate(item.updatedAt)}}
        </template>
        <template v-slot:item.lastOpened="{item}" class="ml-0">
          {{formatLocalDate(item.lastOpened, true)}}
        </template>
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'

import {mapState} from "vuex";
import shared from "@/services/shared";
import EventBus from "@/services/EventBus";

const UPLOAD_PATH = process.env.VUE_APP_ROOT_API+"/v1/content/";

export default {
  name: 'Reporting',
  components: { ConfirmDialog},
  data () {
    return {
      courses: [],
      origCourses: [],
      team: [],
      instance: "demo",
      search: '',
      search_team: "",
      headers_team: [
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('firstName'), value: 'firstName' },
        { text: this.$t('lastName'), value: 'lastName' },
        { text: this.$t('last activity'), value: 'lastActivity' },
      ],
      instanceString: '',
      showActivityList: true,
      allTags: [],
      selectedTags: [],
      sortBy: 'lastOpened',
      sortDesc: true,
      numStudents: 0
    }
  },
  async mounted() {
    this.setContentHeader();

  },
  computed: {
    ...mapState(['user']),
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('opened'),
        value: 'lastOpened',
        sortable: true
      },
      {
        text: this.$t("User List"),
        value: 'numStudents',
        sortable: true
      }
    ]},
    headerProps(){return{
      sortByText: this.$t('Sort')
    }

    }
  },

  methods: {
    updateList(){
      this.courses = [];
      // eslint-disable-next-line no-unused-vars
      this.origCourses.forEach((value, index) => {
        let addCourse = true;
        // eslint-disable-next-line no-unused-vars
        this.selectedTags.forEach((tag, j) => {

          if(value.tags && value.tags.length > 0) {
            let foundTag = false;
            // eslint-disable-next-line no-unused-vars
            value.tags.forEach((courseTag, k) => {
              //console.log(courseTag.id);
              if(courseTag.id == tag){
                foundTag = true;
              }
            });
            if(!foundTag){
              addCourse = false;
            }
          }else{
            addCourse = false;
          }
        });
        if(addCourse){
          this.courses.push(value);
        }
      });
    },
    removeTag (item) {
      const index = this.selectedTags.indexOf(item.id)
      if (index >= 0) this.selectedTags.splice(index, 1);
      this.updateList();
    },
    setContentHeader(){
      EventBus.$emit('showTopImage', false, 300);
    },
    itemSelected(item){
      console.log("show details "+item.id);
      //this.$router.push({name: 'course', params:{ course: item, instance: this.instance }} )
    },
    getUserName(){
      return shared.getUserName(this.user);
    },
    async deleteItem (course) {
      await this.$refs.confirmDelete.show({
        title: 'Kurs entfernen',
        text: `Wollen sie <b>${course.title}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteCourse(course)
      this.courses = await APIService.getCourses(this.$route.params.instance)
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    formatLocalDate(date, time = false) {
      if(date == ""){
        return "";
      }
      return shared.formatLocaleDate(date, time);
    },
    async fetchCourses() {
      //get last visited course
      let res = await APIService.getUserEvents("61a6372243c4a9002fba54f4", "openCourseEvent");
      this.team = await APIService.getTeamMembers(this.user.id);
      console.log(this.team);
      //console.log(res.results);
      let openedDates = [];
      for(let i=0; i<res.results.length; i++){
        if(res.results[i].type == 'openCourseEvent'){
          openedDates.push(res.results[i]);
        }
      }

      //add statistics to team member
      for(let i=0; i<this.team.length; i++){
        let value = this.team[i];
        value.lastActivity = "";
        console.log(value);
        let user_events = (await APIService.getUserEvents(value.id)).results;
        console.log(user_events);
        if(user_events != null) {
          value.events = user_events;
          user_events.forEach((event) => {
            if (event.type == 'openCourseEvent') {
              value.lastActivity = event.date;
            }
          });
        }
      }


      this.origCourses = await APIService.getUserCourses(this.user.id);

      this.allTags = await APIService.getTags()

      this.courses = this.origCourses;



      for(let i=0; i < this.courses.length; i++){
          let value = this.courses[i];
          value.imagePath = "";
          if(value.image){
            value.imagePath = UPLOAD_PATH + value.image.path;
          }
          value.lastOpened = "";
          for(let i=0; i<openedDates.length; i++){
            //console.log(value.id+" == "+openedDates[i].courseId);
            if(value.id == openedDates[i].courseId){

              value.lastOpened = openedDates[i].date;
              break;
            }
          }
          //add statistics

      }
      console.log(this.courses);

    }
  },
  async created() {
    this.instance = this.$route.params.instance;
    this.setContentHeader();
    await this.fetchCourses();
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => vm.instance = to.params.instance)
  },
  async beforeRouteUpdate(to,from,next) {
    this.instance = to.params.instance;
    await this.fetchCourses();
    next(vm => vm.instance = to.params.instance)
  }
}
</script>

<style>
  .transparent{
    background:transparent !important;
  }
  input{
    color:white;
  }
  .v-input input{
    color: white !important;
    background: rgba(255,255,255,0.2);
    border-radius: 5px;
    padding-left: 5px;
  }
  .v-icon{
    ;
  }
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  .v-input__control > .v-input__slot:after{
    content: none !important;
  }
  .v-input__control > .v-input__slot:before{
    content: none !important;
  }
  .tile-container{
    max-width: 1240px;
  }
  .v-sheet .activityrow{
    background-color: transparent !important;
    background: transparent !important;
  }
  .lastactcard{
    border-radius: 5px;
    margin:10px;
  }
  #searchfield{
    margin-left: 10px;
    margin-bottom: 10px;
  }
</style>
