<template>
  <div>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>{{$t('signup')}}</h1>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
              :label="$t('firstName')"
              prepend-icon="mdi-account-circle"
              v-model="firstname"
          />
          <v-text-field
              :label="$t('lastName')"
              prepend-icon="mdi-account-circle"
              v-model="lastname"
          />
          <v-text-field
            :label="$t('email')"
            prepend-icon="mdi-account-circle"
            v-model="email"
            :disabled="emailDisabled"
          />
          <v-text-field
            :label="$t('password')"
            prepend-icon="mdi-lock"
            :type="showLogin ? 'text' : 'password'"
            :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showLogin=!showLogin"
            v-model="password"
          />
          <div class="s3m_password_requirements">{{$t("password_requirements")}}</div>
          <v-text-field
              :label="$t('password repeat')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password_2"
          />
          <v-checkbox
              v-model="privacy"
          >
            <template v-slot:label>
              <div>
                {{$t("I agree to the")}}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                        target="_blank"
                        :href="privacy_link"
                        @click.stop
                        v-on="on"
                    >
                      {{$t('Privacy')}}
                    </a>
                  </template>
                  {{$t('Opens in new window')}}
                </v-tooltip>
                {{$t('privacy_end')}}
              </div>
            </template>
          </v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          class="mx-auto px-4"
          @click="signup"
        >
          {{$t('signup')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="loginFail"
      color="error"
      multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import APIService from "@/services/APIService";
const validator = require('validator');
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import shared from "@/services/shared";

export default {
  name: 'Signup',
  data () {
    return {
      email: '',
      emailDisabled: false,
      password: '',
      password_2: '',
      firstname: '',
      lastname: '',
      privacy: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      token: '',
      privacy_link:`${origin}/ext/Datenschutzerklaerung.pdf`,

    }
  },
  mounted() {
    if(this.$route.query.token){
      this.token = this.$route.query.token;
      let token_parts = this.token.split(".");
      let userData = shared.decodeToken(token_parts[1]);
      if(userData.emailName){
        this.email = userData.emailName;
      }else{
        this.email = userData.email;
      }
      this.emailDisabled = true;
      this.firstname = userData.firstName;
      this.lastname = userData.lastName;
    }else{
      this.emailDisabled = false;
    }
  },
  methods: {
    ...mapMutations(['updateUser']),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['logout']),
    signup () {
      if((this.password !== this.password_2) || this.password === "" || !validator.isEmail(this.email)){
        this.loginFailMessage = this.$t("register_fail_message");
        this.loginFail = true;
        return;
      }
      if(this.password.length < 8 || !this.validate(this.password)){
        this.loginFailMessage = this.$t("password_malformed");
        this.loginFail = true;
        return;
      }
      console.log(this.privacy);
      if(!this.privacy){
        this.loginFailMessage = this.$t("Please agree to the privacy policy");
        this.loginFail = true;
        return;
      }
      let user_new = {};
      //user_new.loginName = this.email;
      user_new.email = this.email;
      user_new.password = this.password;
      user_new.firstName = this.firstname;
      user_new.lastName = this.lastname;
      if(this.token && this.token != ""){
        user_new.token = this.token;
      }
      // eslint-disable-next-line no-unused-vars
      APIService.registerUser(user_new).then((data) => {
        this.$router.push({name: 'userlogin', params:{ instance: 'lms' }} )
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        console.log(error);
        this.loginFailMessage = this.$t("email_taken");
        this.loginFail = true;
      })

    },
    validate(value) {
      if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }],
      ...mapState(['user']),
    origin() {
      return window.location.protocol + "//" + window.location.hostname +
          (window.location.port ? ':' + window.location.port: '');
    }
    }
}
</script>

<style scoped>

</style>
